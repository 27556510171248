import classes from './route.module.css';

import { Alert, Anchor, Button, Group, SimpleGrid, Stack, TextInput, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCornerUpRight, IconRocket, IconSearch, IconSearchOff } from '@tabler/icons-react';
import cx from 'clsx';
import { useMemo, useState } from 'react';

import { approximateStringMatch } from '@rockawayxlabs/observatory-utils';

import { useLoaderData } from '~/features/serialization';

import { ZoneCard } from './components/ZoneCard';
import type { loader } from './route.loader.server';

export { ErrorBoundary } from './route.boundary';
export { loader } from './route.loader.server';
export { meta } from './route.meta';

export default function LandingPage() {
  const { zones } = useLoaderData<typeof loader>();

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, 300);

  const filteredZones = useMemo(() => {
    if (!debouncedSearch) {
      return zones;
    }

    return zones.filter((zone) => approximateStringMatch(zone.name, debouncedSearch));
  }, [zones, debouncedSearch]);

  const zonesWithSensors = useMemo(() => filteredZones.filter((zone) => zone.sensorEnabled), [filteredZones]);
  const zonesWithoutSensors = useMemo(() => filteredZones.filter((zone) => !zone.sensorEnabled), [filteredZones]);

  return (
    <Stack gap="xl">
      <TextInput
        leftSection={<IconSearch size={16} />}
        placeholder="Search blockchains"
        size="md"
        value={search}
        autoFocus
        onChange={(e) => setSearch(e.target.value)} />

      {zonesWithSensors.length + zonesWithoutSensors.length <= 0 && search.length > 0 &&
      <Alert title="No blockchains found" icon={<IconSearchOff />} radius="md" color="orange">
          Try searching different blockchain name.{' '}
          <Anchor component="button" color="orange" onClick={() => setSearch('')}>
            Or clear your search keyword.
          </Anchor>
        </Alert>}

      {zonesWithSensors.length > 0 &&
      <SimpleGrid
        cols={{
          base: 1,
          sm: 2,
          md: 3
        }}
        spacing="sm">

          {zonesWithSensors.map((zone) =>
        <ZoneCard key={zone.id} zone={zone} />
        )}
        </SimpleGrid>}

      {zonesWithSensors.length + zonesWithoutSensors.length > 0 &&
      <Group justify="space-between">
          <Tooltip
          label="These blockchains are being monitored by sensors which makes stake concentration data and related scores much more precise."
          withinPortal
          multiline
          classNames={{ tooltip: classes.tooltip }}>

            <Button
            component="div"
            size="xs"
            variant="subtle"
            color="gray"
            leftSection={<IconCornerUpRight size={18} className={classes.arrowIconUp} />}
            className={cx(classes.nonInteractiveButton, {
              [classes.invisible || '']: zonesWithSensors.length <= 0
            })}>

              Sensors Online
            </Button>
          </Tooltip>
          <Tooltip
          label="Stake concentration data and related scores may be skewed on these blockchains because they are not being monitored by sensors and so we are unable to match some nodes to validators."
          withinPortal
          multiline
          classNames={{ tooltip: classes.tooltip }}>

            <Button
            component="div"
            size="xs"
            variant="subtle"
            color="gray"
            rightSection={<IconCornerUpRight size={18} className={classes.arrowIconDown} />}
            className={cx(classes.nonInteractiveButton, {
              [classes.invisible || '']: zonesWithoutSensors.length <= 0
            })}>

              Sensors Unavailable
            </Button>
          </Tooltip>
        </Group>}

      {zonesWithoutSensors.length > 0 &&
      <SimpleGrid
        cols={{
          base: 1,
          sm: 2,
          md: 3
        }}
        spacing="sm">

          {filteredZones.
        filter((zone) => !zone.sensorEnabled).
        map((zone) =>
        <ZoneCard key={zone.id} zone={zone} />
        )}
        </SimpleGrid>}

      <Alert title="More to come" icon={<IconRocket />} radius="md">
        Keep up with the new blockchains and features we&apos;re introducing. Follow us on{' '}
        <Anchor href="https://x.com/observatoryzone" target="_blank" rel="noreferrer">
          X
        </Anchor>{' '}
        and join{' '}
        <Anchor href="https://discord.gg/zawuNJzVu4" target="_blank" rel="noreferrer">
          Discord community of validators.
        </Anchor>
      </Alert>
    </Stack>);

}